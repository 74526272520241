import { portalToken } from '@x/api';
import { VALIDATE_AUTH_EVENT } from '@x/config';
import { cleanObject } from '@x/utils';
import Axios, { AxiosRequestConfig, CreateAxiosDefaults } from 'axios';
import * as R from 'ramda';

type RyvitRequestOptions<TQueryParams> = {
  params?: TQueryParams;
};

type RyvitRequestConfigOptionalBody<TRequestBody, TQueryParams> = Omit<
  AxiosRequestConfig<TRequestBody>,
  'params'
> &
  RyvitRequestOptions<TQueryParams>;

type RyvitRequestConfigRequiredBody<TRequestBody, TQueryParams> = Omit<
  RyvitRequestConfigOptionalBody<TRequestBody, TQueryParams>,
  'data'
> & {
  data: TRequestBody;
};

export type RyvitRequestConfig<
  TRequestBody = unknown,
  TQueryParams = unknown,
> = Partial<TRequestBody> extends TRequestBody
  ? RyvitRequestConfigOptionalBody<TRequestBody, TQueryParams>
  : RyvitRequestConfigRequiredBody<TRequestBody, TQueryParams>;

function authRequestInterceptor(config: RyvitRequestConfig<unknown>) {
  const token = portalToken();

  return R.compose(
    R.mergeRight({ headers: { Accept: 'application/json' } }),
    R.when(
      R.always(token),
      R.assocPath(['headers', 'authorization'], `Bearer ${token}`),
    ),
    R.modify('params', cleanObject), // remove nil values from params
  )(config);
}

const DEFAULT_CONFIG: CreateAxiosDefaults = {
  paramsSerializer: {
    indexes: null, // https://github.com/axios/axios/issues/5058#issuecomment-1272107602
  },
};

export const axiosRaw = Axios.create(DEFAULT_CONFIG);
export const axios = Axios.create(DEFAULT_CONFIG);

axiosRaw.interceptors.request.use(authRequestInterceptor);
axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    window.dispatchEvent(new Event(VALIDATE_AUTH_EVENT));

    throw error;
  },
);
