import { URLS } from '@x/api';
import { ApiMutationConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { UserPathParams } from '../types';

type PutUserUnlockConfig = ApiMutationConfig<void, UserPathParams>;

const url = `${URLS.account}/v1/users/$userId/unlock`;

export const putUserUnlock = createApiEndpoint<PutUserUnlockConfig>({
  url,
  method: 'PUT',
  invalidateNthAncestor: 1,
});
