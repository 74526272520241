import { URLS } from '@x/api';
import { ApiMutationConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import {
  AccountInviteAssignment,
  InvitePathParams,
  InviteShort,
  PostUserBody,
  PutUserBody,
  UserInvitePathParams,
} from '../types';

type PostInviteUserConfig = ApiMutationConfig<
  PostUserBody,
  InvitePathParams,
  InviteShort
>;

type PutInviteUserConfig = ApiMutationConfig<
  PutUserBody,
  UserInvitePathParams,
  AccountInviteAssignment
>;

const postUrl = `${URLS.account}/v1/invites/$inviteId/user`;
const putUrl = `${URLS.account}/v1/invites/$inviteId/user/$userId`;

export const postInviteUser = createApiEndpoint<PostInviteUserConfig>({
  url: postUrl,
  method: 'POST',
});

export const putInviteUser = createApiEndpoint<PutInviteUserConfig>({
  url: putUrl,
  method: 'PUT',
});
