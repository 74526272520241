export enum StepType {
  createTextFile = 'createtextfile',
  assertion = 'assertion',
  buildEdi = 'buildedi',
  cacheWrite = 'cachewrite',
  callFlow = 'callflow',
  cdxProjectLinkLookup = 'cdxprojectlinklookup',
  combineDelimitedFiles = 'combinedelimitedfiles',
  if = 'if',
  createDelimitedFile = 'createdelimitedfile',
  createUserTask = 'createusertask',
  createWorkItem = 'createworkitem',
  sendEmail = 'sendemail',
  encryptFile = 'encryptfile',
  extractZipV1 = 'extractzipv1',
  extractZipV2 = 'extractzipv2',
  filter = 'filter',
  forEach = 'foreach',
  procoreToViewpointIntegrationActions = 'procoretoviewpointintegrationactions',
  getWorkItemsInCollection = 'getworkitemsincollection',
  groupBy = 'groupby',
  lookup = 'getdatafromcachev2',
  mapJson = 'mapjsonv3',
  parseCsvToJson = 'parsecsvtojson',
  parseDelimitedFileToJson = 'parsedelimitedfiletojson',
  parseExcelToJson = 'parseexceltojson',
  connectorAction = 'queueactionv3',
  readCsv = 'readcsv',
  distinct = 'distinct',
  resolveWorkItems = 'resolveworkitems',
  lookupRelatedDataObjects = 'lookuprelatedataobjects',
  relateDataObjects = 'relatedataobjects',
  unrelateDataObjects = 'unrelatedataobjects',
  stop = 'stop',
}
