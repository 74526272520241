import { URLS } from '@x/api';
import { ApiMutationConfig, ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import {
  GetWorkspacesQueryParams,
  GetWorkspacesResponse,
  PostWorkspaceBody,
  Workspace,
} from '../types';

type GetWorkspacesConfig = ApiQueryConfig<
  GetWorkspacesResponse,
  unknown,
  GetWorkspacesQueryParams
>;

type PostWorkspaceConfig = ApiMutationConfig<
  PostWorkspaceBody,
  unknown,
  Workspace
>;

const url = `${URLS.account}/v1/workspaces`;

export function getWorkspaces(
  config?: Partial<GetWorkspacesConfig>,
): GetWorkspacesConfig {
  return createApiEndpoint<GetWorkspacesConfig>({
    url,
    method: 'GET',
    params: {
      pageSize: 10,
      pageNumber: 1,
    },
  })(config);
}

export const postWorkspaces = createApiEndpoint<PostWorkspaceConfig>({
  url,
  method: 'POST',
});
