import { CloseCircleTwoTone } from '@ant-design/icons';
import { VSpace } from '@x/components';
import { xTheme } from '@x/styles';
import { Button, Card, Typography } from 'antd';
import { UnauthorizedPage } from 'components/Layout/UnauthorizedPage';
import React from 'react';

const { colors } = xTheme;

export function ErrorFallback(): React.JSX.Element {
  return (
    <UnauthorizedPage top={'35%'}>
      <Card style={{ textAlign: 'center' }}>
        <VSpace>
          <CloseCircleTwoTone
            twoToneColor={colors.error}
            style={{ fontSize: xTheme.fontSizes[6] }}
          />
          <Typography.Title level={3}>Ooops!</Typography.Title>
          <Typography.Text type="secondary">
            There was a problem that caused the page to crash.
          </Typography.Text>
          <Button
            type="primary"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Back to Home
          </Button>
          <Button
            type="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh Page
          </Button>
        </VSpace>
      </Card>
    </UnauthorizedPage>
  );
}
