import { URLS } from '@x/api';
import { ApiMutationConfig, ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import {
  GetInviteResponse,
  InvitePathParams,
  PutInviteBody,
  Workspace,
} from '../types';

type InviteShort = {
  id: string;
  email: string;
  expiresOn: string;
  isNewUser: boolean;
  roles: string[];
  workspace: Workspace;
};

type GetInviteConfig = ApiQueryConfig<GetInviteResponse, InvitePathParams>;

type GetInviteForEmailConfig = ApiQueryConfig<
  GetInviteResponse,
  InvitePathParams
>;

type PostInviteConfig = ApiMutationConfig<
  PutInviteBody,
  InvitePathParams,
  InviteShort
>;

type DeleteInviteConfig = ApiMutationConfig<unknown, InvitePathParams>;

const url = `${URLS.account}/v1/invites/$inviteId`;

export const getInvite = createApiEndpoint<GetInviteConfig>({
  url,
  method: 'GET',
});

export const getInviteEmail = createApiEndpoint<GetInviteForEmailConfig>({
  url,
  method: 'GET',
});

export const putInvite = createApiEndpoint<PostInviteConfig>({
  url,
  method: 'PUT',
});

export const deleteInvite = createApiEndpoint<DeleteInviteConfig>({
  url,
  method: 'DELETE',
  invalidateNthAncestor: 1,
});
