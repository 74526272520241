import { FileApi } from './FlowDefinitionTypes';
import { PlatformEventData } from './FlowEvents';
import { WorkspaceData } from './FlowTypes';
import { StepType } from './StepType';

export type FlowRunError = {
  workflowInstanceId: string;
  errorMessage: string;
  stepId: string | null;
  timestamp: string;
  errorType: string;
  stackTrace: string | null;
  expressionPath: string | null;
};

export type FlowRunErrors = {
  errors: FlowRunError[];
  childInstanceErrors: FlowRunError[];
};

export type FlowRunEmails = {
  id: number;
  subscriberId: number;
  parentWorkflowInstanceId: string;
  workflowInstanceId: string;
  stepId: string;
  stepName: string;
  emails: string[];
  subject: string;
  body: string;
  attachmentName: string;
  attachmentUrl: string;
};

export enum FlowRunStepStatus {
  success = 'SUCCESSFUL',
  queueActionSuccess = 'Successful',
  actionSuccess = 'ACTION_HANDLED_SUCCESSFUL',
  actionFailed = 'ACTION_HANDLED_FAILED',
  queueSuccess = 'QUEUE_ACTION_SUCCESSFUL',
  badRequest = 'BAD_REQUEST',
  error = 'ERROR',
  suspended = 'SUSPENDED',
  halted = 'HALTED',
  exception = 'EXCEPTION',
  cancelled = 'CANCELED',
  true = 'True',
  false = 'False',
  stopSuccess = 'success',
  stopFailure = 'failure',
}

export type StepV2 = {
  data?: unknown;
  hasErrors: boolean;
  id: string;
  name?: string;
  inputData?: {
    FlowToCallCode?: string;
    Condition?: boolean;
    Status?: string; //TODO: specify values
    RunInParallel?: boolean;
    MaxIterationsAllowed?: number | null;
    FileIds?: string[];
    FileApi?: FileApi;
    //TODO: better types for these arrays
    trueSteps?: unknown[];
    falseSteps?: unknown[];
    steps?: unknown[];
  };
  status: FlowRunStepStatus;
  stepType: StepType;
  terminateInstance: boolean;
};

export type FlowRunJsonInstanceV2 = {
  children: Record<string, any>;
  startedOn: string;
  finishedOn: string;
  instanceId: string;
  parentInstanceId?: string;
  name?: string;
  rootInstanceId: string;
  stepsRan: StepV2[];
  trigger: {
    data: unknown;
    info?: PlatformEventData;
    workspace: WorkspaceData;
  };
};

export type FlowRunFileMetadata = {
  id: string;
  filename: string;
};
