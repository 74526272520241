import { FlowEvaluators } from '@x/types';
import { EventPayloadFilter } from './FlowTypes';

export type FlowDefinition = {
  id: number;
  createdDate: string;
  containerId: number;
  name: string;
  steps: FlowStep[];
  trigger: FlowTrigger | null;
  configurationSchema?: string | null;
  engineVersion?: number;
  evaluator?: FlowEvaluators | number | null;
  createdFromDefinitionId: number | null;
};

export type FlowStep = {
  id: string;
  name: string;
  type: string;
  input: FlowStepInput;
  stepDependencies: FlowStepDependency[];
  $add?: boolean;
  index?: number;
  stepPath?: string;
  hasStandardStepDependency?: boolean;
};

export enum FileApi {
  AppNetwork,
  Portal,
}

export type FlowStepDependency = {
  dependentOnStepId: string;
  dependentOnStepResultStatus: string | null;
};

type FlowStepInput = Record<string, unknown> & {
  InputJson?: Record<string, unknown>;
  steps?: FlowStep[];
  falseSteps?: FlowStep[];
  trueSteps?: FlowStep[];
};

export type FlowTrigger = {
  type?: FlowTriggerEventType;
  definition?: TriggerDefinition;
};

export type FlowTriggerEventType =
  | 'siCacheWrite'
  | 'siActionClosed'
  | 'onDemand'
  | 'batchReady';

export type TriggerDefinition =
  | ActionClosedTriggerDefinition
  | CacheEventTriggerDefinition
  | OnDemandTriggerDefinition
  | BatchReadyTriggerDefinition;

type OnDemandTriggerDefinition = {
  inputSchemaType?: string;
  inputSchema?: string;
  schedules?: OnDemandSchedule[];
};

type OnDemandSchedule = {
  frequency: string;
  daysOfWeek?: string[];
  daysOfMonth?: string[];
  time: string;
  timeOffest: string;
  enabled?: boolean;
};

type ActionClosedTriggerDefinition = {
  app: string;
  networkApp: string;
  actionPath: string;
  description: string;
  eventPayloadFilters: EventPayloadFilter[];
};

type CacheEventTriggerDefinition = {
  app: string;
  networkApp: string;
  dataObjectPath: string;
  description: string;
  eventPayloadFilters: EventPayloadFilter[];
  cacheEvents: string[];
};

type BatchReadyTriggerDefinition = {
  WorkRequestDefinitionKey: string;
  WorkItemType: 'cache_record' | 'action';
  App: string;
  WorkItemCollectionKey: string;
  description: string;
};

export type PostFlowDefinitionsBody = Omit<
  FlowDefinition,
  'id' | 'createdDate'
>;

export type PutFlowContainerBody = {
  name: string;
  code: string | null;
  description: string | null;
  isRemediation: boolean;
  containsSensitiveData: boolean;
};
