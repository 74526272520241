import { URLS } from '@x/api';
import { ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { GetWorkspacesQueryParams, GetWorkspacesResponse } from '../types';

type GetWorkspacesConfig = ApiQueryConfig<
  GetWorkspacesResponse,
  unknown,
  GetWorkspacesQueryParams
>;

const url = `${URLS.account}/v2/workspaces`;

export function getWorkspacesV2(
  config?: Partial<GetWorkspacesConfig>,
): GetWorkspacesConfig {
  return createApiEndpoint<GetWorkspacesConfig>({
    url,
    method: 'GET',
    params: {
      pageSize: 10,
      pageNumber: 1,
    },
  })(config);
}
