import { ItemType } from 'antd/lib/menu/interface';
import * as R from 'ramda';

export function getPrimaryConnectionActionLabel({
  hasChanges,
  isConnected,
  canEditConnection,
  isTesting,
}: {
  hasChanges: boolean;
  isConnected?: boolean;
  canEditConnection: boolean;
  isTesting: boolean;
}): string {
  if (isTesting) return 'Testing';

  if (hasChanges) {
    if (isConnected) return 'Save Changes';

    return canEditConnection ? 'Save & Connect' : 'Save & Test';
  }

  if (isConnected) return 'Disconnect';

  return canEditConnection ? 'Connect' : 'Test';
}

export function getConnectionActionItems({
  hasChanges,
  isConnected,
  canEditConnection,
  canDelete,
  isAuthCodeFlow,
}: {
  hasChanges: boolean;
  isConnected?: boolean;
  canEditConnection: boolean;
  canDelete: boolean;
  isAuthCodeFlow?: boolean;
}): ItemType[] {
  return [
    {
      key: 'save',
      label: 'Save Changes',
      include: hasChanges,
    },
    {
      key: 'connect',
      label: 'Connect',
      include: !isConnected && canEditConnection,
    },
    {
      key: 'disconnect',
      label: 'Disconnect',
      include: isConnected && canDelete,
    },
    {
      key: 'test',
      label: 'Test',
      include: !isAuthCodeFlow || isConnected,
    },
  ]
    .filter(R.prop('include'))
    .map(R.dissoc('include'));
}
