import { BasePaginationParams, JsonSchema } from '@x/types';
import { ConnectionSummary } from './connectionTypes';

export type ConnectorRegistration = {
  id: number;
  connector: ConnectorSummary;
  configuration: Record<string, any> | null;
  connection: ConnectionSummary | null;
  workspace: {
    id: number;
    accountId: number;
  };
};

export type ConnectorSummary = {
  id: string;
  key: string;
  name: string;
  hasDefinitions: boolean;
};

export type ConnectorAvailability = 'public' | 'private' | 'internal';
export enum ConnectorBuildType {
  CODE = 'code',
  API = 'api',
  SDK = 'sdk',
  UI = 'ui',
}
export type ConnectorStatus =
  | 'available'
  | 'underConstruction'
  | 'beta'
  | 'deprecated';

export type Connector = {
  id: string;
  key: string;
  name: string;
  description?: string;
  version: string | null;
  status: ConnectorStatus;
  buildType: ConnectorBuildType;
  availability: ConnectorAvailability;
  createdOn: string;
  updatedOn: string | null;
  account: {
    id: number;
    name: string;
  };
  logo: string | null | undefined;
};

export type PostConnectorsRequest = {
  name: string;
  key: string;
  description?: string;
  accountId: number;
  buildType: ConnectorBuildType;
  cdxEnabled?: boolean;
};

export type ConnectorFilters = {
  status?: ConnectorStatus | ConnectorStatus[];
  availability?: ConnectorAvailability | ConnectorAvailability[];
  buildType?: ConnectorBuildType | ConnectorBuildType[];
  accountId?: number;
  search?: string;
  id?: string;
};

export type ConnectorModule = {
  name: string;
  key: string;
  version: number;
  connectorKey: string;
  connectorId: string;
  apiBaseUrl: string;
  description: string;
};

export type ConnectorDataObjectUniqueIdentifier = {
  key: string;
  uniqueIdentifiers: string[];
  isPrimary: boolean;
};

export type ConnectorAction = {
  name: string;
  dataObjectId: number;
  key: string;
  description: string;
  inputJsonSchema: JsonSchema;
  successfulJsonSchema: JsonSchema;
  failedJsonSchema: JsonSchema;
  connectorKey: string;
  moduleKey: string;
  dataObjectKey: string;
  uniqueIdentifierLocation: 'inputJson' | 'outputJson';
  uniqueIdentifierPath: string;
  actionHandlerRegistration: {
    id: number;
    configJson: Record<string, unknown>;
    actionPath: string;
    deregister: boolean;
    type: 'ServiceRunner'; //Apparently this is currently the only possible value
  };
};

export type ConnectorDataObject = {
  name: string;
  key: string;
  description: string;
  schemaJson: JsonSchema;
  cached: boolean;
  connectorKey: string;
  moduleKey: string;
  moduleVersion: number;
  apiBaseUrl: string;
  connectorId: string;
  partnerSystemId: number;
  uniqueIdentifiers: ConnectorDataObjectUniqueIdentifier[];
  actions: ConnectorAction[];
};

export type GetConnectorsQueryParams = BasePaginationParams & {
  accountId?: number;
  search?: string;
  buildType?: ConnectorBuildType[];
  availability?: ConnectorAvailability[];
  status?: ConnectorStatus[];
  orderBy?: string;
  ascending?: string;
};
