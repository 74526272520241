import { ThemeProvider } from '@emotion/react';
import { initDayjs } from '@x/lib';
import {
  AntdProvider,
  history,
  LayoutProvider,
  PromptProvider,
  queryClient,
  SignalrContextProvider,
  UserProvider,
  XAuthProvider,
} from '@x/stores';
import { xTheme } from '@x/styles';
import '@x/styles/src/index.less';
import { ErrorBoundary } from 'ErrorBoundary';
import { ErrorFallback } from 'ErrorFallback';
import 'lib/exposeLibs';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router-dom';
import { worker } from '__mockServer__/browser';
import { App } from './App';

initDayjs();

if (process.env.REACT_APP_USE_MOCK === 'true') {
  // Start the MSW service worker in development mode only
  worker.start();
}

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={xTheme}>
      <AntdProvider>
        <XAuthProvider>
          <Router history={history}>
            <LayoutProvider>
              <UserProvider>
                <SignalrContextProvider>
                  <PromptProvider>
                    <ErrorBoundary fallback={<ErrorFallback />}>
                      <App />
                    </ErrorBoundary>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </PromptProvider>
                </SignalrContextProvider>
              </UserProvider>
            </LayoutProvider>
          </Router>
        </XAuthProvider>
      </AntdProvider>
    </ThemeProvider>
  </QueryClientProvider>,
);
