import { URLS } from '@x/api';
import { ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { AccountWorkpacePathParams, Workspace } from '../types';

type GetAccountWorkspaceConfig = ApiQueryConfig<
  Workspace,
  AccountWorkpacePathParams
>;
const url = `${URLS.account}/v2/workspaces/$workspaceId`;

export const getWorkspaceV2 = createApiEndpoint<GetAccountWorkspaceConfig>({
  url,
  method: 'GET',
});
