import { URLS } from '@x/api';
import { ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { GetRolesWorkspaceResponse } from '../types';

type GetRolesWorkspaceConfig = ApiQueryConfig<GetRolesWorkspaceResponse>;

const url = `${URLS.account}/v1/roles/accounts`;

export const getAccountRoles = createApiEndpoint<GetRolesWorkspaceConfig>({
  url,
  method: 'GET',
});
