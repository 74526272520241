import { URLS } from '@x/api';
import { ApiMutationConfig, ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { User, UserPathParams } from '../types';

type PutUserBody = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

type GetUserConfig = ApiQueryConfig<User, UserPathParams>;
type PutUserConfig = ApiMutationConfig<PutUserBody, UserPathParams, User>;

const url = `${URLS.account}/v1/users/$userId`;

export const getUser = createApiEndpoint<GetUserConfig>({ url, method: 'GET' });
export const putUser = createApiEndpoint<PutUserConfig>({
  url,
  method: 'PUT',
});
