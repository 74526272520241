export type OAS = {
  openapi: string;
  info: Info;
  servers?: Server[];
  paths: Paths;
  externalDocs?: ExternalDocumentation;
  security?: SecurityRequirement[];
  tags?: Tag[];
  components?: OasComponents;
};

export type Info = {
  title: string;
  version: string;
  description?: string;
  termsOfService?: string;
  contact?: Contact;
  license?: License;
};

export type Server = {
  url: string;
  description?: string;
  variables?: Record<string, ServerVariable>;
};

export type Paths = Record<string, PathItem>;

export type PathItem = {
  $ref?: string;
  summary?: string;
  description?: string;
  servers?: Server[];
  parameters?: Parameter[];
  get?: Operation;
  put?: Operation;
  post?: Operation;
  delete?: Operation;
  patch?: Operation;
  head?: Operation;
  trace?: Operation;
  options?: Operation;
};

export type Operation = {
  responses: Responses;
  tags?: string[];
  summary?: string;
  description?: string;
  externalDocs?: ExternalDocumentation;
  operationId?: string;
  parameters?: Parameter[] | Reference[];
  requestBody?: RequestBody | Reference;
  callbacks?: Record<string, Callback | Reference>;
  deprecated?: boolean;
  security?: SecurityRequirement[];
  servers?: Server[];
};

type Contact = {
  name?: string;
  url?: string;
  email?: string;
};

type License = {
  name: string;
  url?: string;
};

type ServerVariable = {
  default: string;
  enum?: string[];
  description?: string;
};

export type Parameter = {
  name: string;
  in: ParameterLocation;
  description?: string;
  required?: boolean;
  deprecated?: boolean;
  allowEmptyValue?: boolean;
  style?: SerializationStyle;
  explode?: boolean;
  allowReserved?: boolean;
  example?: Record<string, unknown>; //example and examples are mutually exclusive
  examples?: Record<string, Example | Reference>;
  $ref?: string;
  schema?: Schema | Reference;
  content?: Content;
};

export type Content = Record<string, MediaType>;

export const ParameterLocations = [
  'query',
  'header',
  'path',
  'cookie',
] as const;

export type ParameterLocation = typeof ParameterLocations[number];

export const SerializationStyles = [
  'matrix',
  'label',
  'form',
  'simple',
  'spaceDelimited',
  'pipeDelimited',
  'deepObject',
] as const;

type SerializationStyle = typeof SerializationStyles[number];
type SchemaType =
  | 'string'
  | 'number'
  | 'integer'
  | 'object'
  | 'array'
  | 'boolean'
  | 'null'
  | '$ref';

export type Schema = {
  title?: string;
  multipleOf?: number;
  maximum?: number;
  exclusiveMaximum?: boolean;
  minimum?: number;
  exclusiveMinimum?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  maxItems?: number;
  minItems?: number;
  uniqueItems?: boolean;
  maxProperties?: number;
  minProperties?: number;
  required?: string[];
  enum?: Record<string, unknown>[];
  type?: SchemaType;
  not?: Schema | Reference;
  allOf?: Schema[] | Reference[];
  oneOf?: Schema[] | Reference[];
  anyOf?: Schema[] | Reference[];
  items?: Schema | Reference;
  properties?: Record<string, Schema | Reference>;
  additionalProperties?: Schema | Reference | boolean;
  description?: string;
  format?: string;
  default?: Record<string, unknown>;
  nullable?: boolean;
  discriminator?: Discriminator;
  readonly?: boolean;
  writeonly?: boolean;
  example?: Record<string, unknown>;
  externalDocs?: ExternalDocumentation;
  deprecated?: boolean;
  xml?: XML;
  $ref?: string;
};

type Discriminator = {
  propertyName: string;
  mapping?: Record<string, string>;
};

type ExternalDocumentation = {
  url: string;
  description?: string;
};

type XML = {
  name?: string;
  namespace?: string;
  prefix?: string;
  attribute?: boolean;
  wrapped?: boolean;
};

export type Reference = {
  $ref: string;
};

export type MediaType = {
  schema?: Schema | Reference;
  example?: Record<string, unknown>;
  examples?: Record<string, Example | Reference>;
  encoding?: Record<string, Encoding>;
};

type Example = {
  summary?: string;
  description?: string;
  value?: Record<string, unknown>;
  externalValue?: string;
};

type Encoding = {
  contentType?: string;
  headers?: Header | Reference;
  style?: SerializationStyle;
  explode?: boolean;
  allowReserved?: boolean;
};

type Header = Omit<Parameter, 'name' | 'in'> & {
  style?: 'simple';
  schema?: { type?: string };
};

type Responses = Record<string, Response | Reference>;

export type Response = {
  description: string;
  headers?: Record<string, Header | Reference>;
  content?: Record<string, MediaType>;
  links?: Record<string, Link>;
};

type Link = {
  operationId?: string;
  operationRef?: string;
  parameters?: {
    [key: string]: Record<string, unknown>;
  };
  requestBody?: Record<string, unknown>;
  description?: string;
  server?: Server;
};

export type RequestBody = {
  content: Content;
  description?: string;
  required?: boolean;
};

type Callback = Record<string, PathItem>;

export type SecurityRequirement = Record<string, string[]>;

type Tag = {
  name: string;
  description?: string;
  externalDocs?: ExternalDocumentation;
};

export type OasComponents = {
  schemas?: Record<string, Schema | Reference>;
  responses?: Record<string, Response | Reference>;
  parameters?: Record<string, Parameter | Reference>;
  examples?: Record<string, Example | Reference>;
  requestBodies?: Record<string, RequestBody | Reference>;
  headers?: Record<string, Header | Reference>;
  securitySchemes?: SecuritySchemes;
  links?: Record<string, Link | Reference>;
  callbacks?: Record<string, Callback | Reference>;
};

export type SecurityScheme =
  | APIKeySecurityScheme
  | HTTPSecurityScheme
  | OAuth2SecurityScheme
  | OpenIdConnectSecurityScheme;

export type SecuritySchemes = Record<string, SecurityScheme | Reference>;

type APIKeySecurityScheme = {
  type: 'apiKey';
  name: string;
  in: 'header' | 'query' | 'cookie';
  description?: string;
  'x-token-from-login'?: CustomAuthToken;
};

type HTTPSecurityScheme = {
  scheme: string;
  type: 'http';
  bearerFormat?: string;
  description?: string;
};

type OAuth2SecurityScheme = {
  type: 'oauth2';
  flows: OauthFlows;
  description?: string;
};

export type OauthFlows = {
  implicit?: ImplicitOAuthFlow;
  password?: PasswordOAuthFlow;
  clientCredentials?: ClientCredentialsFlow;
  authorizationCode?: AuthorizationCodeOAuthFlow;
};

type ImplicitOAuthFlow = {
  authorizationUrl: string;
  scopes?: {
    [key: string]: string;
  };
  refreshUrl?: string;
};

type PasswordOAuthFlow = {
  tokenUrl: string;
  scopes?: {
    [key: string]: string;
  };
  refreshUrl?: string;
};

type ClientCredentialsFlow = PasswordOAuthFlow;

type AuthorizationCodeOAuthFlow = ImplicitOAuthFlow & PasswordOAuthFlow;

type OpenIdConnectSecurityScheme = {
  type: 'openIdConnect';
  openIdConnectUrl: string;
  description?: string;
};

export type CustomAuthToken = {
  apiPath: string;
  operation: string;
  usernamePath: string;
  passwordPath: string;
  tokenIn: string;
  tokenPath?: string;
  tokenLocation?: string;
};
