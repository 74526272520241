import { URLS } from '@x/api';
import { PaginationParamsCamelCase } from '@x/types';
import { ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { GetWorkspacesResponse } from '../types';

type AccountPathParams = { $accountId: number };
type AccountQueryParams = PaginationParamsCamelCase;
type GetAccountConfig = ApiQueryConfig<
  GetWorkspacesResponse,
  AccountPathParams,
  AccountQueryParams
>;

const url = `${URLS.account}/v1/accounts/$accountId/customers`;

export const getAccountCustomers = createApiEndpoint<GetAccountConfig>({
  method: 'GET',
  url,
});
