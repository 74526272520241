import { ActivityLog, PaginatedResponse } from '@x/types';

export type Account = {
  id: number;
  name: string;
  createdOn: string;
  createdBy: string;
  featureKeys: AccountFeatureKey[];
};

export enum AccountFeatureKey {
  FlowBuilder = 'FlowBuilder',
  IntegrationBuilder = 'IntegrationBuilder',
  ConnectorBuilder = 'ConnectorBuilder',
}

export type AccountFeature = {
  id: string;
  key: AccountFeatureKey;
  name: string;
};

export type GetAccountsQueryParams = {
  accountId?: number;
  ascending?: boolean;
  orderBy?: string;
  pageNumber?: number;
  pageSize?: number;
  search?: string;
};

export type UpsertAccountRequest = {
  name: string;
  featureKeys: AccountFeatureKey[];
};

export type GetRolesWorkspaceResponse = {
  roles: WorkspaceRole[];
};

export type AccountAssignmentResponse = {
  accountAssignments: AccountAssignment[];
};

export type PutWorkspaceAssignmentsBody = {
  roles: UserRole[];
  workspaceId: number;
  isDefault: boolean;
};

export type PutAccountAssignmentsBody = {
  roles: UserRole[];
  accountId: number;
  isHomeAccount: boolean;
};

export type UserInvitePathParams = {
  $inviteId: string;
  $userId: string;
};

export type GetWorkspacesQueryParams = {
  pageNumber?: number;
  pageSize?: number;
  search?: string;
  orderBy?: string;
  ascending?: boolean;
  type?: WorkspaceType | WorkspaceType[];
  accountId?: number;
};

export type GetWorkspacesResponse = PaginatedResponse<Workspace>;

export type PostWorkspaceBody = {
  name: string;
  type: WorkspaceType;
  accountId: number;
};
export type PutAccountWorkspaceBody = { name: string };

export type PostInvitesBody = {
  emails: string[];
  accountAssignment: {
    roles: UserRole[];
    accountId: number;
  };
};

export type User = {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  lockoutEnabled: boolean;
  accountId?: number;
  type?: UserType;
};

export type UserPathParams = { $userId: string };

export enum WorkspaceType {
  Unknown = 'unknown',
  Ryvit = 'ryvit',
  Partner = 'partner',
  Standard = 'standard',
  Project = 'project',
  Custom = 'custom',
  Development = 'integrationDevelopment',
}

export type Workspace = {
  id: number;
  guid: string;
  name?: string;
  activationCode?: string;
  createdUtc: string;
  type: WorkspaceType;
  accountId: number;
  accountName?: string;
  key: string;
  urlPart: string;
};

export type AccountAssignment = {
  roles: UserRole[];
  account: Account;
  isHomeAccount: boolean;
};

export type WorkspaceInvite = {
  id: number;
  key: string;
  name?: string;
  activationCode?: string;
  createdUtc: string;
  type?: WorkspaceType;
};

export type AccountInvite = {
  id: number;
  name?: string;
};

export type AccountInviteAssignment = {
  isDefault: boolean;
  roles: UserRole[];
  account: AccountInvite;
};

export type InviteShort = {
  id: string;
  email: string;
  expiresOn: string;
  isNewUser: boolean;
  roles: UserRole[];
  account: AccountInvite;
};

export type Invite = {
  id: string;
  email: string;
  roles: UserRole[];
  expiresOn: string;
  isNewUser: boolean;
  accountName: string;
  accountId: number;
  inviter: string;
  inviterName: string;
};

export type GetInvitesResponse = PaginatedResponse<Invite>;

export type InvitePathParams = {
  $inviteId: string;
};

export type GetInviteResponse = {
  email: string;
  invites: InviteShort[];
};

export type PutInviteBody = {
  roles: UserRole[];
  accountId: number;
};

export type PostUserBody = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
  defaultAccountAssignment: AccountInviteAssignment;
};

export type PutUserBody = {
  roles: UserRole[];
  accountId: number;
  isHomeAccount: boolean;
};

export enum UserRoleDisplayName {
  Admin = 'Administrator',
  User = 'User',
  ReadOnly = 'Read Only',
  FlowAuthor = 'Flow Author',
  IntegrationAuthor = 'Integration Author',
  PlatformAdmin = 'Platform Administrator',
  AppBuilderAuthor = 'App Builder Author',
  ConnectorAuthor = 'Connector Author',
}

export enum UserRole {
  AccountAdmin = 'AccountAdmin',
  User = 'AccountUser',
  ReadOnly = 'AccountReadOnly',
  FlowAuthor = 'FlowAuthor',
  IntegrationAuthor = 'IntegrationAuthor',
  PlatformAdmin = 'PlatformAdmin',
  AppBuilderAuthor = 'AppBuilderAuthor',
  ConnectorAuthor = 'ConnectorAuthor',
}

export type WorkspaceRole = {
  id: string;
  name: UserRole;
  displayName?: UserRoleDisplayName;
  descriptionDetails: string[];
};

export type UserType = 'application' | 'service';

export type GetUsersParams = {
  PageSize?: number;
  PageNumber?: number;
  OrderBy?: string;
  Ascending?: boolean;
  WorkspaceId?: number;
  AccountId?: number;
  Search?: string;
  Type?: UserType;
};

export type AccountWorkpacePathParams = { $workspaceId: number };

export type WorkspaceActivityLog = ActivityLog & {
  workspaceId: number;
  integrationId?: number;
  registrationId?: number;
  instanceId?: string;
  oldValues?: string;
  newValues?: string;
  taskId?: string;
  recordCount?: number;
  bulkRunFilters?: string;
  scheduleId?: number;
  registrationProjectId?: string;
  jobSequenceId?: number;
};
