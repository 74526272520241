import { URLS } from '@x/api';
import { AccountFeature, ApiQueryConfig } from 'api/types';
import { createApiEndpoint } from 'api/utils';

const url = `${URLS.account}/v1/features`;

type GetFeaturesResponse = ApiQueryConfig<AccountFeature[]>;

export const getFeatures = createApiEndpoint<GetFeaturesResponse>({
  url,
  method: 'GET',
});
