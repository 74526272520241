import { URLS } from '@x/api';
import { ApiMutationConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { UserPathParams } from '../types';

type PutUserPasswordBody = { password: string };
type PutUserPasswordConfig = ApiMutationConfig<
  PutUserPasswordBody,
  UserPathParams
>;

const url = `${URLS.account}/v1/users/$userId/password`;

export const putUserPassword = createApiEndpoint<PutUserPasswordConfig>({
  url,
  method: 'PUT',
});
