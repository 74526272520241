import { URLS } from '@x/api';
import { VSpace } from '@x/components';
import { useQueryString } from '@x/hooks';
import { cleanObject, safeParseJson } from '@x/utils';
import {
  Alert,
  Button,
  Divider,
  Flex,
  Image,
  Result,
  Space,
  theme,
} from 'antd';
import { getAuthLSKey } from 'features/connection/utils';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/header-logo.png';

function parseAuthState(state?: string): {
  connectionId: string;
  additionalState?: Record<string, any>;
} {
  if (!state) {
    return { connectionId: '' };
  }

  const decoded = atob(state);
  const { connectionId, additionalState } = safeParseJson({}, decoded);

  return cleanObject({
    connectionId,
    additionalState: safeParseJson(undefined, additionalState),
  });
}

export function CallbackPage(): React.JSX.Element {
  const { token } = theme.useToken();
  const { result } = useParams<{ result: 'success' | 'fail' }>();
  const { state, errorMessage } = useQueryString();
  const { connectionId } = parseAuthState(state);
  const storageKey = getAuthLSKey(connectionId);
  const [timer, setTimer] = useState(2);
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    localStorage.setItem(storageKey, result);

    if (result === 'fail') {
      return;
    }

    if (timer === 0) {
      window.close();
      return;
    }

    setTimeout(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
  }, [result, storageKey, timer]);

  function renderResult() {
    if (result === 'fail') {
      return (
        <VSpace>
          <Result
            status="error"
            title="Authentication failed"
            extra={
              <Space>
                <Button onClick={() => window.close()}>Cancel</Button>
                <a
                  data-testid="try-again"
                  href={`${URLS.appBuilder}/v1/oauth/authenticate/${connectionId}`}
                >
                  <Button
                    loading={spin}
                    type="primary"
                    onClick={() => setSpin(true)}
                  >
                    Try Again
                  </Button>
                </a>
              </Space>
            }
          />
          {errorMessage && (
            <Alert
              showIcon
              message="Error"
              description={errorMessage}
              type="error"
              style={{ margin: token.marginLG, marginTop: 0 }}
            />
          )}
        </VSpace>
      );
    }

    return (
      <Result
        status="success"
        title="Authentication succeeded"
        extra={
          <Button type="primary" onClick={() => window.close()}>
            Close
          </Button>
        }
      />
    );
  }

  return (
    <VSpace>
      <div>
        <Flex justify="center" align="center">
          <Image preview={false} src={Logo} width={200} />
        </Flex>
        <Divider style={{ marginTop: token.marginXS }} />
      </div>
      {renderResult()}
    </VSpace>
  );
}
