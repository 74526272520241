import { URLS } from '@x/api';
import { ApiMutationConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { UserPathParams } from '../types';

type PutUserLockConfig = ApiMutationConfig<void, UserPathParams>;

const url = `${URLS.account}/v1/users/$userId/lock`;

export const putUserLock = createApiEndpoint<PutUserLockConfig>({
  url,
  method: 'PUT',
  invalidateNthAncestor: 1,
});
