import { URLS } from '@x/api';
import { ApiMutationConfig } from '../../types';
import { createApiEndpoint } from '../../utils';

type AccountAssignmentPathParams = { $userId: string; $accountId: number };

type DeleteUserAccountsConfig = ApiMutationConfig<
  void,
  AccountAssignmentPathParams
>;

const url = `${URLS.account}/v1/users/$userId/accounts/$accountId`;

export const deleteUserAccount = createApiEndpoint<DeleteUserAccountsConfig>({
  url,
  method: 'DELETE',
});
