import { cleanObject } from '@x/utils';
import { Tooltip } from 'antd';
import { OAuthClientListItem } from 'api/types';
import { JsonFormSchema } from 'features/formRenderer/types';

export function makeSchemaPropertiesFromAttributes({
  attributes,
  isNew,
  isConnected,
  canEditConnection = true,
  oauthClientOptions,
}: {
  attributes?: Record<string, any>[];
  isNew?: boolean;
  isConnected?: boolean;
  canEditConnection?: boolean;
  oauthClientOptions?: OAuthClientListItem[];
}): Record<string, any> {
  function getDisabledMessage() {
    if (!canEditConnection) {
      return 'You cannot edit this connection.';
    }

    if (isConnected) {
      return 'Disconnect to change this value.';
    }

    return;
  }

  const tooltip = getDisabledMessage();

  return attributes?.reduce(
    (acc: any, attr) => {
      const writeOnly = !isNew && attr.isSensitive;
      const disabled = Boolean(tooltip);
      const property: JsonFormSchema = {
        type: 'string',
        required: attr.isRequired,
        description: attr.description,
        readOnly: Boolean(tooltip),
        renderer: attr.options ? 'select' : undefined,
        writeOnly,
        inputProps: cleanObject({
          tooltip,
          disabled,
          options: attr.options,
          optionRender: attr.options
            ? (option: any) => (
                <Tooltip title={option.data.description}>
                  {option.data.label}
                </Tooltip>
              )
            : undefined,
        }),
      };

      return { ...acc, [attr.key]: property };
    },
    oauthClientOptions
      ? {
          oAuthClientId: {
            type: 'string',
            title: 'OAuth Client',
            required: true,
            readOnly: !isNew,
            renderer: 'select',
            writeOnly: !isNew,
            inputProps: cleanObject({
              tooltip: !isNew
                ? 'This cannot be changed after creation.'
                : undefined,
              options: oauthClientOptions.map((client) => ({
                label: client.name,
                value: client.id,
              })),
            }),
          },
        }
      : {},
  );
}

export function makeConnectionFormSchema({
  schemaProperties,
  canEditName,
}: {
  schemaProperties?: Record<string, any>;
  canEditName?: boolean;
}): JsonFormSchema {
  const required = schemaProperties
    ? Object.keys(schemaProperties).filter(
        (key) => schemaProperties[key].required,
      )
    : [];

  return {
    type: 'object',
    required: ['name'],
    properties: {
      name: {
        type: 'string',
        inputProps: {
          autoComplete: 'off',
          tooltip:
            canEditName === false
              ? 'You cannot edit this connection.'
              : undefined,
        },
      },
      configuration: {
        type: 'object',
        required,
        properties: schemaProperties,
      },
    },
  };
}
