import { URLS } from '@x/api';
import { ApiMutationConfig } from '../../types';
import { createApiEndpoint } from '../../utils';

type AuthSendPasswordResetBody = {
  email: string;
};

type AuthSendPasswordResetConfig = ApiMutationConfig<AuthSendPasswordResetBody>;

const url = `${URLS.account}/v1/auth/sendPasswordReset`;

export const postAuthSendPasswordReset =
  createApiEndpoint<AuthSendPasswordResetConfig>({
    url,
    method: 'POST',
    skipInvalidate: true,
  });
